import React from "react";
import Banner from "../../Components/Banner";
import NavBar from "../../Components/NavBar";
import './quienes-somos.css';
import {ReactComponent as RondaLogo} from '../../assets/imgs/Home/ronda-name-big.svg';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import DefImg from '../../assets/imgs/quienes-somos/def-quienes-somos.webp';

import '../../Components/components-css/onplace-art-slider.css';

import { useState, useEffect, useRef } from "react";
import InstagramRow from "../../Components/InstagramRow";
import { QsmMosaico } from "../../Components/Quienes-Somos/QsmMosaico";
import Map from "../../Components/Map";
import BrandGrid from "../../Components/BrandGrid";
import Faqs from "../../Components/Faqs/Faqs";
import Footer from "../../Components/Footer";

import { MobileMenu } from "../../Components/MobileMenu";

import QSM1 from '../../assets/imgs/quienes-somos/carousel/qsm-1.jpg';
import QSM2 from '../../assets/imgs/quienes-somos/carousel/qsm-2.jpg';
import QSM3 from '../../assets/imgs/quienes-somos/carousel/qsm-3.jpg';
import QSM4 from '../../assets/imgs/quienes-somos/carousel/qsm-4.jpg';
import QSM5 from '../../assets/imgs/quienes-somos/carousel/qsm-5.jpg';
import QSM6 from '../../assets/imgs/quienes-somos/carousel/qsm-6.jpg';
import QSM7 from '../../assets/imgs/quienes-somos/carousel/qsm-7.jpg';
import QSM8 from '../../assets/imgs/quienes-somos/carousel/qsm-8.jpg';



function QuienesSomos() {

    const splideRef = useRef(null);
    const [canGoPrev, setCanGoPrev] = useState(true);
    const [canGoNext, setCanGoNext] = useState(true);

    useEffect(() => {
        if (splideRef.current) {
          const splideInstance = splideRef.current.splide;
          splideInstance.on('moved', () => {
            setCanGoPrev(splideInstance.index > 0);
            setCanGoNext(splideInstance.index < splideInstance.length - 1);
          });
          console.log("event loaded");
        }

      }, []);

      const handleMovement = (direction) => {
        if (direction === 'prev') {
          splideRef.current.go('-1');
          console.log('clicked prev');
        } else {
          splideRef.current.go('+1');
          console.log('clicked next');
        }
      }

    return (
        <>
        <MobileMenu/>
        <div className="centering-wrapper" style={{backgroundColor: '#000000'}}>
            <div className="max-width-container">
                
                <NavBar/>
                <div className="qsm-header-wrapper">
                    <div className="section-title section-title-margin">
                        Quienes somos
                    </div>
                    <div className="qsm-header">
                        { window.innerWidth > 478 ? <>Ronda es un espacio que viene a romper con la <br/> dinámica habitual de los shoppings. <br/> Tiene comida, si. Tiene ropa, también. <br/> Y tiene entretenimiento. </> : 
                        <>Ronda es un espacio que viene a romper con la dinámica habitual de los shoppings. Tiene comida, si. <br/> Tiene ropa, también. Y tiene entretenimiento. </>}

                    </div>
                    <div className="qsm-header-bg-svg-wrapper">
                        <div className="qsm-header-bg-svg">
                            <RondaLogo/>
                        </div>
                    </div>
                </div>
            </div>


            <div className="qsm-body-description-wrapper">
                <div className="qsm-body-description">
                Y aunque todo suene conocido, nada lo es. <br/>
                Porque en Ronda todas las propuestas tienen otra vuelta.
                Tiene lo justo de lo clásico y lo necesario de la novedad. La frescura del arte y lo atractivo del diseño. Tiene lo rico de la comida de siempre y la curiosidad de un sabor diferente. Lo divertido de un plan, y la aventura de que ese plan, tenga muchos planes. 
                <br/>
                <br/>Y por eso Ronda es un plan redondo.
                Modestia aparte, no existe otro lugar como este. Por eso, para terminar de entender qué es Ronda, vas a tener que entrar.

                </div>
            </div>


            
            
            <InstagramRow/>
            
            <div className="qsm-map-margin">
                <Map/>
            </div>
            <BrandGrid/>
            <Faqs/>
            <Footer/>
        </div>
        </>
    )
}

export default QuienesSomos;

