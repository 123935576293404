import React, { useEffect } from "react";
import './gastronomia.css';

import Banner from "../../Components/Banner";
import NavBar from "../../Components/NavBar";
import ServiceTitle from "../../Components/ServiceTitle";
import Shop from "../../Components/Shop";

//import mockData from './raw_mock_res.json';
import {mockData} from './MockImport';
import { useState, useRef } from "react";

import {ReactComponent as SearchIcon} from '../../assets/imgs/shops/filter/search.svg';
import BrandGrid from "../../Components/BrandGrid";
import Faqs from "../../Components/Faqs/Faqs";
import FilterBar from "../../Components/FilterBar/FilterBar";
import ExploraTambien from "../../Components/SiteCards/ExploraTambien";
import Footer from "../../Components/Footer";
import ShopPopUp from "../../Components/ShopPopUp";

import { MobileMenu } from "../../Components/MobileMenu";

import { useRequest } from "../../hooks/useRequest";

function Gastronomia () {

    const { getFoodShops, shopsDataUse } = useRequest();
    const [shopDataTest, setShopDataTest] = useState([]);
    
    useEffect(() => {
        getFoodShops();
    }, [])

    useEffect(() => {
        console.log("here", shopsDataUse);
        setShopsData(shopsDataUse);
        setFilteredData(shopsDataUse);
    }, [shopsDataUse])

    const [shopsData, setShopsData] = useState(shopsDataUse);
    
    //search logic
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState(shopsDataUse);
    //const [typeFilter, setTypeFilter] = useState("");
    const [selectedFilter, setSelectedFilter] = useState("TODO");

    const typeFilter = useRef("");

    const handleSearch = (searchInput) => {
        //const searchTerm = event.target.value;
        //const searchTerm = searchInput;
        //const targetData = typeFilter === "" ? shopsData : filteredData;
        setSearchTerm(searchInput);
        const filtData = shopsData.filter(item => 
            item.type.toLowerCase().includes(typeFilter.current.toLowerCase()) && (
            item.title.toLowerCase().includes(searchInput.toLowerCase()) || 
            item.subtitle.toLowerCase().includes(searchInput.toLowerCase()))
        );
        setFilteredData(filtData);
    };

    const handleFilterToggle = (type)  => {

        typeFilter.current = type;
        handleSearch(searchTerm);
        setSelectedFilter(type == "" ? "TODO" : type);

        if (window.innerWidth < 478) {
            setOpenMobileDropdown(false);
        }
    }

    //---

    const dataTypes = ["", "AMERICANO", "ASIAN FOOD", "ITALIANO", "POSTRES", "BEBIDAS", "DESAYUNO", "SUIZO", "FRANCES"];

    const titleData = {
        title: 'GASTRONOMÍA',
        text: 'Una selección curada de propuestas gastronómicas que te invitan a probar de todo. Clásicos argentinos, propuestas emergentes y nuestra recomendación propia. ¿De qué tenés ganas hoy?'
    }

 
    const [openMobileDropdown, setOpenMobileDropdown] = useState(false);

    const handleMobileDropdown = () => {
        if (window.innerWidth < 478) {
            setOpenMobileDropdown(!openMobileDropdown);
        
        }
    }

    const determineMobileStyle = () => {
        if (window.innerWidth < 478) {
            return openMobileDropdown ? {display: 'flex'} : {display: 'none'};
        } else {
            return {display: 'flex'};
        }
    }
    

    return (
        <>
        <MobileMenu/>
        <div className="centering-wrapper centering-overflow" style={{backgroundColor: '#36B675', paddingBottom: 'min(2.7vw, 38.9px)'}}>
             
            <div className="max-width-container">
            <NavBar/>
            
            <ServiceTitle data={titleData}/>
            
           

        <div className="filter-bar-wrapper filter-bar-gastronomia-mobile" style={{marginTop: 'min(5.9vw, 85px)'}}>

            <div className="selected-filter-container hide-desktop" onClick={handleMobileDropdown}>
                <div className="filter-button" style={{backgroundColor: '#216E47'}}>
                    {selectedFilter}
                    <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L10 10L19 1" stroke="white"/>
                </svg>
                </div>


            </div>

            <div className="filter-buttons-container" style={determineMobileStyle()}>
                
                {
                    dataTypes.map((item, index) => (
                        <div className="filter-button" style={typeFilter.current === item ? {backgroundColor: '#216E47'} : {}} onClick={() => {handleFilterToggle(item)}} key={index}>
                            {item === "" ? "TODO" : item}
                        </div>
                    ))
                }
            </div>
            <div className="search-container">
                <div style={{zIndex: 2, position: 'relative'}}>
                <input className="filter-search-bar"
                placeholder="BUSCAR"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                >
                </input>
                </div>
                <div className="search-icon-div">
                    <div className="search-icon">
                        <SearchIcon/>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </div>

        <div className="centering-wrapper" style={{paddingBottom: '100px'}}>
            <div className="max-width-container">
                <div className="grid-container">
                    { Object.keys(filteredData).length !== 0 ? 
                    filteredData.map((item, index) => {
                        return <Shop data={item} key={index} />
                    }) : <div>Sin Resultados!</div>}
                </div>
            </div>
        </div>

        <div className="centering-wrapper sloped-top" style={{backgroundColor: 'black'}}>
            <div className="max-width-container">
                <BrandGrid/>
                <Faqs style={{marginTop: 'min(12.15vw, 175px)'}}/>
                <div className="explora-wrapper explora-wrapper-margin-mobile" style={{marginTop: 'min(12.15vw, 175px)'}}>
                    <div className="section-title section-title-margin">
                        Explorá también
                    </div>
                     <ExploraTambien/>
                </div>
                <div style={{paddingTop: 'min(12.15vw, 175px)'}}>
                <Footer/>
                </div>
            </div>
        </div>
        
        
        </>
    )
}

export default Gastronomia;