import React from 'react';
import Banner from '../../Components/Banner';
import NavBar from '../../Components/NavBar';
import ServiceTitle from '../../Components/ServiceTitle';
import DarkModeWrapper from '../../Components/DarkModeWrapper';
import Faqs from '../../Components/Faqs/Faqs';
import Footer from '../../Components/Footer';
import MockRes from './moda_mock_res.json';
import ModaShop from '../../Components/ModaShop';

import {ReactComponent as Bafweek} from '../../assets/imgs/moda/bafweek.svg';
import EventosCard from '../../Components/SiteCards/EventosCard';
import { MobileMenu } from '../../Components/MobileMenu';

import { useRequest } from '../../hooks/useRequest';
import { useState, useEffect } from 'react';


function Moda() {

    const { getFashionShops, shopsDataUse } = useRequest();
    const [shopDataTest, setShopDataTest] = useState([]);

    useEffect(() => {
        getFashionShops();
    }, [])

    useEffect(() => {

    }, [shopsDataUse])

    return (
        <>
        <MobileMenu/>
        <div className='centering-wrapper' style={{backgroundColor: '#20B2EB', paddingBottom: 'min(11.45vw, 165px)'}}>
            <div className='max-width-container'>
                
                <NavBar/>
                <ServiceTitle data={{title: 'MODA', text: 'Cápsulas de marcas emergentes curadas por BafWeek. Conectá con el mundo de la moda y encontrá siempre algo distinto.'}}/>
            </div>
        </div>
        <div className='centering-wrapper'>
            <div className='max-width-container'>
                <div className='bafweek-wrapper'>
                    <div className='bafweek-header-container'>
                        <div className='bafweek-header-title'>
                            <Bafweek/>
                        </div>
                        <div className='bafweek-header-subtitle'>
                            Presenta
                        </div>
                    </div>
                    <div className='bafweek-grid-container'>
                        {
                            shopsDataUse.map((item, index) => {
                                return <ModaShop data={item} key={index}/>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
        <DarkModeWrapper>
            <Faqs style={{marginTop: 'min(21.11vw, 304px)'}}/>
            <div className='section-title section-title-margin eventos-card-margin'>Explorá también</div>
            <EventosCard/>
            <Footer/>
        </DarkModeWrapper>
        </>
    )
}

export default Moda;