import React from "react";
import './home.css';
import NavBar from '../../Components/NavBar.js';
import Banner from "../../Components/Banner.js";
import VerButton from "../../Components/VerButton.js";

import CoverEating from '../../assets/imgs/Home/cover-eating.webp';
import CoverGirl from '../../assets/imgs/Home/cover-bafweek.webp';
import CoverGirlMobile from '../../assets/imgs/Home/cover-bafweek.webp';
import CoverGuy from '../../assets/imgs/Home/cover-guy.webp';
import CoverPool from '../../assets/imgs/Home/cover-pool.webp';
import {ReactComponent as Spinner} from '../../assets/imgs/Home/spinner.svg';
import {ReactComponent as RondaMarquee} from '../../assets/imgs/Home/ronda-name-big.svg';
import SaboreaCard from "../../Components/SiteCards/SaboreaCard.js";
import ExploraTambien from "../../Components/SiteCards/ExploraTambien.js";
import ModaCard from "../../Components/SiteCards/ModaCard.js";
import ObservaCard from "../../Components/SiteCards/ObservaCard.js";

import Outside from '../../assets/imgs/Home/explora/outside.webp';
import Outside1 from '../../assets/imgs/Home/explora/outside-1.webp';
import Outside2 from '../../assets/imgs/Home/explora/outside-2.webp';
import Outside3 from '../../assets/imgs/Home/explora/outside-3.webp';
import Outside4 from '../../assets/imgs/Home/explora/outside-4.webp';
import BrandGrid from "../../Components/BrandGrid.js";
import Map from "../../Components/Map.js";
import MapWrapper from "../../Components/MapWrapper.js";
import Faqs from "../../Components/Faqs/Faqs.js";
import Footer from "../../Components/Footer.js";
import InstagramRow from "../../Components/InstagramRow.js";

import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import '../../Components/components-css/onplace-art-slider.css';

//import Marquee from "react-fast-marquee";

import { useSlider } from "../../hooks/useSlider.js";

import { MobileMenu } from "../../Components/MobileMenu.js";


import PFP1 from '../../assets/imgs/Home/006.webp';
import PFP2 from '../../assets/imgs/Home/008.webp';
import PFP3 from '../../assets/imgs/Home/005.webp';
import PFP4 from '../../assets/imgs/Home/009.webp';
import PFP5 from '../../assets/imgs/Home/007.webp';


function Home() {

    const {splideRef: splideRef, handleMovement: handleMovement} = useSlider();

    const {splideRef: splideRef2, handleMovement: handleMovement2} = useSlider();

    return (
        <>
        <MobileMenu/>
        <div className="centering-wrapper" style={{backgroundColor: '#000000'}}>
            <div className="max-width-container">
                
                <NavBar/>
                {/*Cover "Un plan redondo*/}
                <div className="ronda-plan-container">
                    <div className="ronda-plan-column-1">
                        <div className="ronda-plan-row-1">
                            <div className="cover-pool-image-wrapper">
                                <img className="cover-pool" src={PFP1} alt="pool table"/>
                            </div>
                            <div className="cover-pool-image-wrapper">
                                <img className="cover-pool" src={PFP2} alt="decoration"/>
                            </div>
                        </div>
                        <div className="ronda-plan-row-2">
                            <div className="cover-pool-image-wrapper">
                                <img className="cover-pool" src={PFP3} alt="pool table"/>
                            </div>
                            <div className="cover-pool-image-wrapper">
                                <img className="cover-pool" src={PFP4} alt="decoration"/>
                            </div>
                        </div>
                    </div>
                    <div className="ronda-plan-column-2">
                        <img className="cover-girl" src={PFP5} alt=""/>
                    </div>
                    <div className="ronda-plan-description-column-wrapper">
                        <div className="ronda-plan-description-container">
                            <div className="plan-description-title">
                                un plan redondo
                            </div>
                            <div className="plan-description-subtitle">
                                Armá tu experiencia en Ronda, o caé e improvisala.
                            </div>
                            
                        </div>
                    </div>
                </div>
                {/*/Cover "Un plan redondo*/}
                <div className="que-es-ronda-wrapper">
                    <div className="que-es-ronda-header-container">
                        <div className="qer-title-container">
                            <div className="que-es-ronda-title">
                                ¿Qué C#R@J* es Ronda?
                            </div>
                            <div className="qer-description" style={{maxWidth: 'min(31.25vw, 600px)'}}>
                                Somos el lugar al que venís a dar vueltas cuando no sabés bien qué querés hacer y también cuando lo tenés clarísimo.

                            </div>
                        </div>
                        <div className="spinner-wrapper">
                            <div className="spinner-container"> {/*fijarse efecto 3d girar*/}
                                <Spinner/>
                            </div>
                            <div className="background-marquee">
                                {/*Measure performance, may be shit, some errors are throwed when state is updated at runtime*/}
                                {/*<Marquee autoFill={true} speed={300}>*/}
                                <div className="marquee-logo-wrapper">
                                    
                                    <RondaMarquee/>
                                    
                                </div>
                                {/*</Marquee>*/}
                            </div>
                        </div>
                    </div>

                    {/* per card component*/}
                    <div className="cards-container hide-mobile">
                        <SaboreaCard/>
                        <ExploraTambien/>
                        <ModaCard/>
                        <ObservaCard/>
                    </div>

                    <div className="hide-desktop">
                        <div className="home-cards-slider-wrapper">
                            <Splide ref={splideRef}
                            options={{
                                type: "loop",
                                drag: 'free',
                                autoWidth: true,
                                autoHeight: true,
                                width: '100vw',
                                gap: '3px',
                                pagination: false,
                                arrows: false,
                                focus: 'center',
                                start: 1
                            }}>
                                <SplideSlide>
                                    <SaboreaCard/>
                                </SplideSlide>
                                <SplideSlide>
                                    <ExploraTambien/>
                                </SplideSlide>
                                <SplideSlide>
                                    <ModaCard/>
                                </SplideSlide>
                                <SplideSlide>
                                    <ObservaCard/>
                                </SplideSlide>
                            </Splide>
                            <div className="home-cards-slider-arrows">
                                <div className="buttons-container" >
                                    <div className="onplace-button onp-button-mobile-home" onClick={() => handleMovement('prev')}>
                                        <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.99195 11.4893L0.597258 6.37937C0.568562 6.35219 0.568562 6.30649 0.597262 6.27931L5.99202 1.1701C6.0054 1.15743 6.01469 1.15265 6.01885 1.15083C6.02319 1.14893 6.02685 1.14813 6.03074 1.14788C6.03961 1.1473 6.05661 1.14962 6.07592 1.16168C6.09523 1.17375 6.10478 1.188 6.10814 1.19623C6.10962 1.19983 6.11051 1.20347 6.11071 1.20821C6.1109 1.21274 6.11068 1.22318 6.10516 1.24076L5.87666 1.96886C4.9857 4.80792 4.98571 7.85175 5.87668 10.6908L6.10508 11.4186C6.1106 11.4362 6.11082 11.4466 6.11063 11.4512C6.11043 11.4559 6.10955 11.4595 6.10807 11.4631C6.1047 11.4714 6.09515 11.4856 6.07584 11.4977C6.05653 11.5097 6.03953 11.5121 6.03066 11.5115C6.02677 11.5112 6.02311 11.5104 6.01877 11.5085C6.01462 11.5067 6.00533 11.5019 5.99195 11.4893Z" stroke="currentColor" stroke-width="1.14527" stroke-linecap="round"/>
                                        </svg>
                                    </div>
                                    <div className="onplace-button onp-button-mobile-home" onClick={() => handleMovement('')}>
                                        <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.00805 11.4893L6.40274 6.37937C6.43144 6.35219 6.43144 6.30649 6.40274 6.27931L1.00798 1.1701C0.994596 1.15743 0.985308 1.15265 0.981153 1.15083C0.97681 1.14893 0.97315 1.14813 0.969262 1.14788C0.96039 1.1473 0.943393 1.14962 0.924082 1.16168C0.904772 1.17375 0.895224 1.188 0.891855 1.19623C0.890378 1.19983 0.889493 1.20347 0.889293 1.20821C0.889102 1.21274 0.889323 1.22318 0.894841 1.24076L1.12334 1.96886C2.0143 4.80792 2.01429 7.85175 1.12332 10.6908L0.894915 11.4186C0.889397 11.4362 0.889176 11.4466 0.889367 11.4512C0.889567 11.4559 0.890452 11.4595 0.891929 11.4631C0.895299 11.4714 0.904847 11.4856 0.924159 11.4977C0.943471 11.5097 0.96047 11.5121 0.969339 11.5115C0.973227 11.5112 0.976886 11.5104 0.981229 11.5085C0.985383 11.5067 0.994671 11.5019 1.00805 11.4893Z" stroke="currentColor" stroke-width="1.14527" stroke-linecap="round"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/*conoce el espacio*/}
                <div className="conoce-el-espacio-wrapper hide-mobile">
                    <div className="section-title">
                        Conocé el espacio
                    </div>
                    <div className="espacio-img-container">
                        <div className="espacio-column-1-container">
                            <img className="espacio-img-1" src={Outside}/>
                        </div>
                        <div className="espacio-column-2-container">
                            <img className="espacio-grid-item" src={Outside1} alt=""/>
                            <img className="espacio-grid-item" src={Outside2} alt=""/>
                            <img className="espacio-grid-item" src={Outside3} alt=""/>
                            <img className="espacio-grid-item" src={Outside4} alt=""/>
                        </div>
                    </div>
                </div>

                <div className="conoce-el-espacio-wrapper hide-desktop">
                    <div className="section-title">
                        Conocé el espacio
                    </div>
                    
                    <div className="espacio-img-container">
                        <Splide ref={splideRef2}
                        options={{
                            type: "loop",
                            drag: 'free',
                            autoWidth: true,
                            autoHeight: true,
                            width: '100vw',
                            gap: '3px',
                            pagination: false,
                            arrows: false,
                            padding: {left: '10px'}
                        }}
                        >
                        <SplideSlide>
                        <div className="espacio-column-1-container">
                            <img className="espacio-img-1" src={Outside}/>
                        </div>
                        </SplideSlide>
                        <SplideSlide>
                        <div className="espacio-column-2-container">
                            <img className="espacio-grid-item" src={Outside1} alt=""/>
                            <img className="espacio-grid-item" src={Outside2} alt=""/>
                            <img className="espacio-grid-item" src={Outside3} alt=""/>
                            <img className="espacio-grid-item" src={Outside4} alt=""/>
                        </div>
                        </SplideSlide>
                        </Splide>
                        <div className="home-cards-slider-arrows" style={{marginTop: 'min(6vw, 25px)'}}>
                            <div className="buttons-container">
                                <div className="onplace-button onp-button-mobile-home" onClick={() => handleMovement2('prev')}>
                                <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.99195 11.4893L0.597258 6.37937C0.568562 6.35219 0.568562 6.30649 0.597262 6.27931L5.99202 1.1701C6.0054 1.15743 6.01469 1.15265 6.01885 1.15083C6.02319 1.14893 6.02685 1.14813 6.03074 1.14788C6.03961 1.1473 6.05661 1.14962 6.07592 1.16168C6.09523 1.17375 6.10478 1.188 6.10814 1.19623C6.10962 1.19983 6.11051 1.20347 6.11071 1.20821C6.1109 1.21274 6.11068 1.22318 6.10516 1.24076L5.87666 1.96886C4.9857 4.80792 4.98571 7.85175 5.87668 10.6908L6.10508 11.4186C6.1106 11.4362 6.11082 11.4466 6.11063 11.4512C6.11043 11.4559 6.10955 11.4595 6.10807 11.4631C6.1047 11.4714 6.09515 11.4856 6.07584 11.4977C6.05653 11.5097 6.03953 11.5121 6.03066 11.5115C6.02677 11.5112 6.02311 11.5104 6.01877 11.5085C6.01462 11.5067 6.00533 11.5019 5.99195 11.4893Z" stroke="currentColor" stroke-width="1.14527" stroke-linecap="round"/>
                                        </svg>
                                </div>
                                <div className="onplace-button onp-button-mobile-home" onClick={() => handleMovement2('')}>
                                <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.00805 11.4893L6.40274 6.37937C6.43144 6.35219 6.43144 6.30649 6.40274 6.27931L1.00798 1.1701C0.994596 1.15743 0.985308 1.15265 0.981153 1.15083C0.97681 1.14893 0.97315 1.14813 0.969262 1.14788C0.96039 1.1473 0.943393 1.14962 0.924082 1.16168C0.904772 1.17375 0.895224 1.188 0.891855 1.19623C0.890378 1.19983 0.889493 1.20347 0.889293 1.20821C0.889102 1.21274 0.889323 1.22318 0.894841 1.24076L1.12334 1.96886C2.0143 4.80792 2.01429 7.85175 1.12332 10.6908L0.894915 11.4186C0.889397 11.4362 0.889176 11.4466 0.889367 11.4512C0.889567 11.4559 0.890452 11.4595 0.891929 11.4631C0.895299 11.4714 0.904847 11.4856 0.924159 11.4977C0.943471 11.5097 0.96047 11.5121 0.969339 11.5115C0.973227 11.5112 0.976886 11.5104 0.981229 11.5085C0.985383 11.5067 0.994671 11.5019 1.00805 11.4893Z" stroke="currentColor" stroke-width="1.14527" stroke-linecap="round"/>
                                        </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                {/*/conoce el espacio*/}
                <BrandGrid/>
                <div className="map-home-wrapper">
                <Map/>
                </div>
                <Faqs style={{marginTop: 'min(12.15vw, 175px)'}}/>
                <InstagramRow/>
   
                <Footer/>
            </div>
        </div>
        </>
    )
}

export default Home;