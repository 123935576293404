import React from "react";
import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'


import { Marker } from "react-leaflet/Marker";


import {ReactComponent as MapIcon} from '../assets/imgs/Home/map-icon.svg';
import customSvgMarker from '../assets/imgs/Home/map-icon.svg';

import L from "leaflet";
import './components-css/map.css';
import VerButton from "./VerButton";

import Dot from '../assets/imgs/footer/dot.webp';

function Map() {
    const position = [-34.54632415,-58.488145016006];
    
    const customIcon = new L.Icon({
        iconUrl: customSvgMarker, // URL or SVG data
        iconSize: [100, 100], // Adjust the size of the marker icon
        iconAnchor: [40, 55], // Adjust the anchor point of the marker icon
      });

    return (
        <>

        <div className="map-wrapper">
            <div className="section-title section-title-margin">
                Dónde estamos
            </div>
            <div className="corner-logo-wrapper">
                <img src={Dot} alt="" style={{all: 'inherit'}}/>
            </div>
            <div className="map-tile-container">
                    <MapContainer center={position} zoom={16} scrollWheelZoom={false} style={{position: 'relative', height: '100%'}}>
                        <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={position} icon={customIcon}>
                        
                        </Marker>
                    </MapContainer>
            </div>
            <div className="map-info-container">
                <div className="map-description">
                    Dirección: Vedia 3600, PB (Dot Baires Shopping) <br/> 
                </div>
                <div className="map-button-row">
                    <VerButton light={true} link={"https://maps.app.goo.gl/8Rmpnb6NpCx8FX5z6"} newtab={true}>Abrir en maps</VerButton>
                </div>
            </div>
    </div>
        </>
    )
}

export default Map;