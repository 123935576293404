import React from 'react';
import '../../Pages/Entretenimiento/entretenimiento.css';

export const EntSiteCover = ({children, style, schedule}) => {
  return (
    <>
    <div className='ent-site-cover-wrapper' style={style}>
        <div className='ent-site-cover-image'>
            {children}
        </div>
        <div className='ent-site-cover-pill-row'>
            <div className='ent-site-pill'>{schedule || 'Todos los días'}</div>
        </div>
    </div>
    </>
  )
}
