// goes on Entretenimiento.js

import React from 'react'
import '../../Pages/Entretenimiento/entretenimiento.css';
import VerButton from '../VerButton';
import '../components-css/shop.css';

import {ReactComponent as PalokoIcon} from '../../assets/imgs/cards/entretenimiento/paloko.svg';
import {ReactComponent as MiniGolfIcon} from '../../assets/imgs/cards/entretenimiento/minigolf.svg';
import {ReactComponent as ObrasBrevesIcon} from '../../assets/imgs/cards/entretenimiento/obras-breves.svg';

import Paloko from '../../assets/imgs/cards/entretenimiento/bowling_.webp';
import MiniGolf from '../../assets/imgs/cards/entretenimiento/pelota-golf-new.webp';
import ObrasBreves from '../../assets/imgs/cards/entretenimiento/teatro.webp';

export const PalokoCard = ({type, className}) => {
  return (
    <>
    <div className={`ent-card-wrapper ${className}`}>
        <div className='ent-card-container' style={type === "paloko" ? {backgroundColor: '#E84E0F'} : type === "minigolf" ? {backgroundColor: '#20B2EB'} : {backgroundColor: '#0A66B0'}}>
            <div className='ent-card-content-container'>
                <div className='ent-card-header-wrapper'>
                    
                    <div className={`ent-svg-${type}`}>
                        {type === 'paloko' && <PalokoIcon/>}
                        {type === 'minigolf' && <MiniGolfIcon/>}
                        {type === 'obras-breves' && <ObrasBrevesIcon/>}
                    </div>
                </div>
                <div className='ent-cover-wrapper'>
                    <img src={type === "paloko" ? Paloko : type === "minigolf" ? MiniGolf : ObrasBreves} className={`ent-cover`} alt='cover' />
                </div>
            </div>
        </div>
        <div className='ent-card-description-wrapper'>
            <div className='ent-card-description'>
                {type === "paloko" && "¡Strikeee! Animate a disfrutar una partida de Bowling en Ronda"}
                {type === "minigolf" && "¡Hoyo en uno! Bienvenidos al MiniGolf"}
                {type === "obras-breves" && "Obras breves, un sin fin de emociones. ¡Que empiece el show!"}
            </div>
            <VerButton link={`/entretenimiento/${type}#`}>Ver todo</VerButton>
        </div>
    </div>
    </>
  )
}
