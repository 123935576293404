import React from "react";
import Banner from "../../Components/Banner";
import NavBar from "../../Components/NavBar";
import ServiceTitle from "../../Components/ServiceTitle";
import OnPlaceArtSlider from "../../Components/OnPlaceArtSlider";
import ExpoActuales from "../../Components/ExpoActuales";
import ExpoTypeBanners from "../../Components/ExpoTypeBanners";
import DarkModeWrapper from "../../Components/DarkModeWrapper";
import Faqs from '../../Components/Faqs/Faqs';
import ExploraTambien from "../../Components/SiteCards/ExploraTambien";
import ExpoItem from "../../Components/ExpoItem";
import EventosCard from "../../Components/SiteCards/EventosCard";
import Footer from "../../Components/Footer";
import './arte.css';

import { MobileMenu } from "../../Components/MobileMenu";

function Arte() {

    const titleData = {
        title: 'ARTE',
        text: 'Podés venir a observar las distintas intervenciones y llevarte lo que quieras de la tienda de Pared. Sentimos el arte como un concepto, una diagonal que atraviesa nuestra totalidad.'
    }

    return (
        <>
        <MobileMenu/>
        <div className="centering-wrapper" style={{backgroundColor: '#E85A27', paddingBottom: 'min(11.45vw, 165px)'}}>
            
           
            <div className="max-width-container">
                {/*<Banner bgColor={'#BA3E0C'}/>*/}
                <NavBar/>
            
                <ServiceTitle data={titleData}/>
            </div>
        </div>
        <div className="centering-wrapper">

            <div className="max-width-container-1920 first-slider-margin">
                <div className="max-width-container">
                <OnPlaceArtSlider/>
                </div>
                <ExpoActuales/>
                
                <DarkModeWrapper style={{marginTop: 'min(15.2vw, 220px)'}}>
                    <Faqs style={{marginTop: 'min(21.11vw, 304px)'}}/>
                    <div className="section-title section-title-margin explora-tambien-title-margin" >Explorá también</div>
                    <EventosCard/>
                    <Footer style={{paddingTop: 'min(16.5vw, 239px)'}}/>
                </DarkModeWrapper>
            </div>
        </div>
        </>
    )
}

export default Arte;