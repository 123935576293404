import React from "react";
import '../Pages/Moda/moda.css';
import VerButton from "./VerButton";
import DefaultImage from '../assets/imgs/moda/moda-default.webp';
import ShopPopUp from "./ShopPopUp";
import { useState } from "react";

function ModaShop({data}) {

    const [showPopup, setShowPopup] = useState(false);

    const closeFromPopUp = () => {
        setShowPopup(false);
    }

    return (
        <>

        {
            showPopup ? <ShopPopUp data={data} show={showPopup} closeCallback={closeFromPopUp} page="" defaultImage={DefaultImage}/> : null
        }

        <div className="shop-moda-container">
            <div className="shop-moda-image-container" onClick={() => {setShowPopup(!showPopup)}}>
                <img className="shop-moda-image" src={data.bgImage == null || data.bgImage == "" ? DefaultImage : data.bgImage} alt="cover local de moda"/>
            </div>
            <div className="flex-row-mobile">
            <div className="shop-moda-description-container">
                <div className="shop-moda-title">
                    {data.title}
                </div>
                <div className="shop-moda-subtitle">
                    {data.subtitle}
                </div>
            </div>
            <div onClick={() => {setShowPopup(!showPopup)}}>
                <VerButton link={data.verMasLink} newtab={true} noLink={true}>Ver más</VerButton>
            </div>
            </div>
        </div>
        </>
    )
}

export default ModaShop;