import React from "react";
import '../components-css/explora-tambien.css';

import {ReactComponent as Header} from '../../assets/imgs/components/entrenimiento-title.svg';
import EntCover from '../../assets/imgs/components/entretenimiento-cover-fin.webp';
import VerButton from "../VerButton";

function ExploraTambien () {
    return (
        <>

            <div className="explora-cards-container">
                <div className="explora-left-card-wrapper">
                    <div className="explora-left-card-content">
                        <div className="header-wrapper">
                            <Header/>
                        </div>
                        <div className="bottom-content-container adjust-gap">
                            
                                <img className="cover-image" src={EntCover} alt="cover de eventos"/>
                            
                            
                        </div>
                    </div>
                </div>
                <div className="explora-right-card" style={{backgroundColor: '#08528D'}}>
                    <div className="explora-right-container">
                        <div className="right-header-container">
                            <div className="right-header-text">Entretenimiento</div>
                            <div className="right-header-title">JUGÁ <br/> Y DIVERTITE</div>
                        </div>
                        <div className="right-bottom-container">
                            <div className="bottom-right-text">
                                Propuestas de entretenimiento que se adaptan a vos.
                            </div>
                            <VerButton text={"Ver más"} link={'/entretenimiento#'} light={false}/>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    )
}

export default ExploraTambien;