import React from "react";
import '../components-css/explora-tambien.css';
import '../components-css/saborea-card.css';


import VerButton from "../VerButton";

import Salad from '../../assets/imgs/Home/salad-new.webp';
import CardPill from "../CardPill";

function SaboreaCard() {
    return (
        <>
            <div className="explora-cards-container" style={window.innerWidth > 478 ? {flexDirection: 'row-reverse'}: {}}>
                <div className="explora-left-card-wrapper" style={{backgroundColor: '#37B876'}}>
                            <img className="salad-img" src={Salad} alt="Salad"/>
                </div>
                <div className="explora-right-card" style={{backgroundColor: '#2C935E'}}>
                    <div className="explora-right-container">
                        <div className="right-header-container">
                            <div className="right-header-text">Gastronomía</div>
                            <div className="right-header-title">SABOREÁ</div>
                        </div>
                        <div className="right-bottom-container">
                            <div className="bottom-right-text">
                                Más de 10 propuestas gastronómicas en un mismo lugar.
                            </div>
                            <VerButton text={"Ver más"} link={'/gastronomia#'} light={false}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SaboreaCard;