import React from 'react'
import { useParams } from 'react-router-dom'
import {Document, Page} from 'react-pdf'
import { useState } from 'react'
import { pdfjs } from 'react-pdf';
//import "react-pdf/dist/esm/Page/TextLayer.css";
//import "pdfjs-dist/build/pdf.worker.min.mjs";
import "react-pdf/src/pdf.worker.entry";
import './menuqr.css';
export const MenuQR = () => {
 

const { name } = useParams();
const [numPages, setNumPages] = useState();
const [pageNumber, setPageNumber] = useState(1);

function onDocumentLoadSuccess({ numPages }) {
  setNumPages(numPages);
}
  //file?path=${name} 
  //<Document file={`https://rondapdfs.s3.us-east-2.amazonaws.com/${name}`} renderMode="svg" className={'svgtest'}>
  //<Document file={`https://ronda-ftp-file-server.vercel.app/file?path=${name}`} renderMode="svg" className={'svgtest'}>

  return (
    <>
 
          <div style={{width: '100%', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Document file={`https://rondapdfs.s3.us-east-2.amazonaws.com/${name}`} renderMode="svg" className={'svgtest'}>
              <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} className={"svgwrapper"}/>
          </Document>
          </div>
        
    
    </>
  )
}


