import React from "react";
import './components-css/brand-grid.css';
import BrandGridSvgs from "./BrandGridSvgs";
import Orno from '../assets/imgs/brand-grid/orno.png';


function BrandGrid() {
    //TODO: Make it dynamic over new file at dir.

    return (
        <>
        <div className="centering-wrapper">
            <div className="max-width-container">
                <div className="our-brands-container">
                    <div className="section-title">
                        Nuestras marcas
                    </div>
                    <div className="brand-grid-container">
                        <div className="brand-grid">
                            <img className="brand-item" src={Orno} alt=""/>
                            {
                                Object.keys(BrandGridSvgs).map((index) => (
                                    <div key={index} className="brand-item">
                                        {BrandGridSvgs[index]}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default BrandGrid;