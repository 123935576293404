import React from 'react';
import Banner from "../../Components/Banner";
import NavBar from "../../Components/NavBar";
import ServiceTitle from '../../Components/ServiceTitle';
import { PalokoCard } from '../../Components/SiteCards/PalokoCard';
import DarkModeWrapper from '../../Components/DarkModeWrapper';
import Faqs from '../../Components/Faqs/Faqs';

import ObservaCard from '../../Components/SiteCards/ObservaCard';
import Footer from '../../Components/Footer';

import { useEffect, useRef } from 'react';

import { MobileMenu } from '../../Components/MobileMenu';

function Entretenimiento() {

    const titleData = {
        title: 'ENTRETENIMIENTO',
        text: 'Vení a jugar, competir, disfrutar! Ronda es un plan con muchos planes y podés elegir el que mejor se adapte a vos.'
    }

    const rowRef = useRef(null);

    useEffect(() => {
    if (window.innerWidth < 478) {
        if (rowRef.current) {
            // Calculate the middle scroll position
            const middlePosition = (rowRef.current.scrollWidth - rowRef.current.clientWidth) / 2;
            rowRef.current.scrollLeft = middlePosition;
        }
    }
    }, []);

    return (
        <>
        <MobileMenu/>

        <div className='centering-wrapper centering-wrapper-padding' style={{backgroundColor: '#0A66B0'}}>
            <div className='max-width-container'>
                
                <NavBar/>
                <ServiceTitle data={titleData}/>
            </div>
        </div>
        <div className='centering-wrapper'>
            <div className='max-width-container'>
                <div ref={rowRef} className='ent-cards-row'>
                    <PalokoCard type={"paloko"}/>
                    <PalokoCard type={"minigolf"}/>
                    <PalokoCard type={"obras-breves"} className={'final-card-margin'}/>
                </div>
            </div>
        </div>
        <DarkModeWrapper>
            <Faqs/>
            <div className='section-title section-title-margin ent-section-title-margin'>Explorá también</div>
            <ObservaCard/>
            <Footer/>
        </DarkModeWrapper>

        </>
    )
}

export default Entretenimiento;