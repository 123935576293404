import React from "react";
import { useState, useEffect } from "react";
import './components-css/shop.css';

import Sushi from '../assets/imgs/shops/sushi.webp';
import {ReactComponent as VeganIcon} from '../assets/imgs/shops/icons/vegan.svg';
import {ReactComponent as CeliacIcon} from '../assets/imgs/shops/icons/celiac.svg';
import ShopPopUp from "./ShopPopUp";


function Shop({data}) {
    
    const [showPopup, setShowPopup] = useState(false);

    const closeFromPopUp = () => {
        setShowPopup(false);
    }

    return (
        <>
        {
            showPopup ? <ShopPopUp data={data} show={showPopup} closeCallback={closeFromPopUp}/> : null
        }
        <div className="card-container" onClick={() => {setShowPopup(!showPopup)}}>
            <div className="image-container">
                <div className="pill-row-container">
                    <div className="place-pill" style={!data.inPlace ? {display: 'none'} : {}}>
                        In Place
                    </div>
                    <div className="place-pill" style={!data.takeAway ? {display: 'none'} : {}}>
                        Take Away
                    </div>
                </div>
                <img className="bg-image" src={data.bgImage == null ? Sushi : data.bgImage} alt="imagen de local de gastronomia"/>
            </div>

            <div className="shop-description-container">
                <div className="shop-name">
                    {data.title}
                </div>
                <div className="shop-type">
                    {data.subtitle != "" ? data.subtitle : <br/>}
                </div>
                <div className="icon-row">
                    <div className="food-icon" style={!data.vegan ? {display: 'none'} : {}}>
                        <VeganIcon/>
                    </div>
                    <div className="food-icon" style={!data.celiac ? {display: 'none'} : {}}>
                        <CeliacIcon/>
                    </div>
                </div>
            </div>
            
        </div>
        </>
    )
}

export default Shop;