import React from 'react';
import Banner from '../../Components/Banner';
import ServiceTitle from '../../Components/ServiceTitle';
import NavBar from '../../Components/NavBar';
import { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { EventForm } from './EventForm';

import DarkModeWrapper from '../../Components/DarkModeWrapper';
import Faqs from '../../Components/Faqs/Faqs';

import SaboreaCard from '../../Components/SiteCards/SaboreaCard';

import Footer from '../../Components/Footer';

import { MobileMenu } from '../../Components/MobileMenu';

function Eventos() {
    const titleData = {
        title: 'EVENTOS',
        text: '¡Armá tu evento en Ronda como más te guste! Distintas propuestas gastronómicas y de entretenimiento en un espacio único. Un plan con otra vuelta que se adapta a vos.'
    }
 
    return (
        <>
        <MobileMenu/>
        <div className='centering-wrapper' style={{backgroundColor: '#F0F0F0'}}>
            <div className='max-width-container'>
                
                <NavBar dark={true}/>
                <ServiceTitle data={titleData} dark={true} />
                <EventForm/>
            </div>
        </div>
        <div className='centering-wrapper' style={{backgroundColor: 'black'}}>
            <div className='max-width-container'>
                <DarkModeWrapper>
                        <Faqs/>
                        <div className='section-title section-title-margin section-title-spacing'>Explorá también</div>
                        <SaboreaCard/>        
                        <Footer/>
                </DarkModeWrapper>
            </div>
        </div>
        </>
    )
}

export default Eventos;