import React from "react";

import '../components-css/explora-tambien.css';
import '../components-css/saborea-card.css';

import {ReactComponent as Header} from '../../assets/imgs/components/entrenimiento-title.svg';
import EntCover from '../../assets/imgs/components/entretenimiento-cover.webp';
import VerButton from "../VerButton";

import Salad from '../../assets/imgs/Home/salad.webp';
import CardPill from "../CardPill";

import {ReactComponent as Kuyen} from '../../assets/imgs/cards/kuyen.svg';
import {ReactComponent as Delucca} from '../../assets/imgs/cards/delucca.svg';
import {ReactComponent as Dionis} from '../../assets/imgs/cards/dionis.svg';

import Moda from '../../assets/imgs/cards/moda-cover.webp';



function ModaCard() {
    return (
        <>
            <div className="explora-cards-container">
                <div className="explora-left-card-wrapper" style={{backgroundColor: '#20B2EB'}}>
                    <div className="moda-left-card-content">
                        <div className="moda-card-brand-row">
                            <div className="kuyen-wrapper ">
                                <Kuyen/>
                            </div>
                            
                        </div>
                        <img className="moda-avatar" src={Moda} alt="moda-avatar"/>
                    </div>
                </div>
                <div className="explora-right-card" style={{backgroundColor: '#1A8EBC'}}>
                    <div className="explora-right-container">
                        <div className="right-header-container">
                            <div className="right-header-text">MODA</div>
                            <div className="right-header-title">DESCUBRÍ</div>
                        </div>
                        <div className="right-bottom-container">
                            <div className="bottom-right-text" style={{maxWidth: 'min(22.2vw, 321px)'}}>
                                Cápsulas de Moda con marcas emergentes curadas por BafWeek.
                            </div>
                            <VerButton text={"Ver más"} link={'/moda'} light={false}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModaCard;