import React from 'react'

import Banner from '../../../Components/Banner';
import { EntSiteCover } from '../../../Components/entretenimiento/EntSiteCover';
import NavBar from '../../../Components/NavBar';

import {ReactComponent as PalokoLogo} from '../../../assets/imgs/entretenimiento/paloko-logo.svg';

import VerButton from '../../../Components/VerButton';
import DarkModeWrapper from '../../../Components/DarkModeWrapper';
import Footer from '../../../Components/Footer';
import { OtherEnts } from '../../../Components/entretenimiento/OtherEnts';

import { MobileMenu } from '../../../Components/MobileMenu';

import '../entretenimiento.css';

import Paloko1 from '../../../assets/imgs/paloko/paloko-1.jpeg';
import Paloko2 from '../../../assets/imgs/paloko/paloko-2.jpeg';
import Paloko3 from '../../../assets/imgs/paloko/paloko-3.jpeg';
import Paloko4 from '../../../assets/imgs/paloko/paloko-4.jpeg';
import Paloko5 from '../../../assets/imgs/paloko/paloko-5.jpeg';
import Paloko6 from '../../../assets/imgs/paloko/paloko-6.jpeg';

function Paloko() {

    const bold = {fontWeight: 600};

    return (
        <>
        <MobileMenu/>
        <div className='centering-wrapper' style={{backgroundColor: '#E84E0F'}}>
            <div className='max-width-container'>
                
                <NavBar/>
                <EntSiteCover>
                    <div className='paloko-logo'>
                        <PalokoLogo/>
                    </div>
                </EntSiteCover>
            </div>
        </div>
        <div className='centering-wrapper'>
            <div className='max-width-container'>
                <div className='ent-site-body-wrapper'>
                    <div className='ent-site-header-wrapper'>
                        <div className='ent-site-title'>
                        ¡Strikeee! Animate a disfrutar una partida de Bowling en Ronda
                        </div>
                        <div className='ent-site-description'>
                            Divertite en las 11 pistas de bowling de Paloko, que se renueva en Ronda con una estética única y una propuesta gastronómica que hacen que el plan sea un Strike.
                        </div>
                    </div>
                    {/*make it a component?*/}
                    <div className='ent-site-card-grid-wrapper'>
                        <div className='ent-col-1'>
                            <img style={{all: 'inherit'}} src={Paloko1} alt=""/>
                        </div>
                        <div className='ent-col-2'>
                            <div className='ent-col-2-row-1 square-radius'>
                                <img className='mosaic-image' src={Paloko2} alt=""/>
                            </div>
                            <div className='ent-col-2-row-2 square-radius'>
                                <img src={Paloko3} alt="" style={{all: 'inherit'}}/>
                            </div> 
                        </div>
                        <div className='ent-col-3'>
                            <div className='ent-col-3-row-1'>
                                <div className='ent-col-3-row-1-col-1 square-radius'>
                                    <img src={Paloko4} alt="" style={{all: 'inherit'}}/>
                                </div>
                                <div className='ent-col-3-row-1-col-2 square-radius'>
                                    <img src={Paloko5} alt="" style={{all: 'inherit'}}/>
                                </div>
                            </div>
                            <div className='ent-col-3-row-2 square-radius'>
                                <img src={Paloko6} alt="" style={{all: 'inherit'}}/>
                            </div>
                        </div>
                    </div>
                    {/*/component*/}
                    
                    <div className='call-to-events-wrapper'>
                        <div className='call-to-events-title'>
                            QUERÉS FESTEJAR TU CUMPLE JUGANDO? CONTACTANOS!
                        </div>
                        <VerButton link={'/eventos#'}>Contactanos</VerButton>
                    </div>


                </div>
            </div>
        </div>
        <div className='centering-wrapper' style={{backgroundColor: '#F0F0F0', marginBottom: '-20px'}}>
            <div className='max-width-container'>
                <OtherEnts/>
            </div>
        </div>
        <DarkModeWrapper>
            <Footer entSite={true}/>
        </DarkModeWrapper>

        </>
    )
}

export default Paloko;
