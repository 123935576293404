import React from "react";
import '../Pages/Gastronomia/gastronomia.css';

function DarkModeWrapper({children, style}) {

    return (
        <div className="centering-wrapper sloped-top" style={{backgroundColor: 'black', ...style}}>
            <div className="max-width-container">
                {children}
            </div>
        </div>
    )
}

export default DarkModeWrapper;