import React from "react";
import { useRef, useEffect, useState } from "react";
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import './components-css/onplace-art-slider.css';

import MockData from './expo_act_mock_res.json';
import MockDataObras from './expo_act_mock_res_obras.json'; //pass as a prop?
import ExpoItem from "./ExpoItem"; 
import ShopPopUp from "./ShopPopUp";
import { usePopUp } from "../hooks/usePopUp";
import { useRequest } from "../hooks/useRequest";

function ExpoActuales({type}) {

    const {getExposiciones, shopsDataUse} = useRequest();

    useEffect(() => {
        getExposiciones();

    }, [])

    useEffect(() => {
        setMockResponse(shopsDataUse);
    }, [shopsDataUse])

    const splideRef = useRef(null);
    const [canGoPrev, setCanGoPrev] = useState(true);
    const [canGoNext, setCanGoNext] = useState(true);

    //experimental
    const [mockResponse, setMockResponse] = useState(MockData);

    useEffect(() => {
        if (type === "obras") {
            setMockResponse(MockDataObras);
        }
    }, [])
    //-experimental
    

    useEffect(() => {
        if (splideRef.current) {
          const splideInstance = splideRef.current.splide;
          splideInstance.on('moved', () => {
            setCanGoPrev(splideInstance.index > 0);
            setCanGoNext(splideInstance.index < splideInstance.length - 1);
          });
          console.log("event loaded");
        }

      }, []);

      const handleMovement = (direction) => {
        if (direction === 'prev') {
          splideRef.current.go('-1');
          console.log('clicked prev');
        } else {
          splideRef.current.go('+1');
          console.log('clicked next');
        }
      }

      //pop up handling
      //had to move popup handling outside of the expo item because when the splide is set to loop it breaks event handling.
      //it works when splide is set to slide. but it treats the splide wrapper as position relative.
      //also, when looping, the clones dont handle the click events as expected.

      const { popupData, showPopup, openPopup, closePopup } = usePopUp();

    return (
        <>
  
        <div className="onplace-wrapper expo-act-gap">
            <div className="onplace-header-container">
                <div className="onplace-header-absolute-container">
                    <div className="onplace-header-title">
                        {type === "obras" ? "Obras en función" : "Exposiciones actuales"}
                    </div>
                </div>
                <div className="buttons-container hide-mobile">
                    <div className="onplace-button" onClick={() => handleMovement('prev')} >
                        <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.99195 11.4893L0.597258 6.37937C0.568562 6.35219 0.568562 6.30649 0.597262 6.27931L5.99202 1.1701C6.0054 1.15743 6.01469 1.15265 6.01885 1.15083C6.02319 1.14893 6.02685 1.14813 6.03074 1.14788C6.03961 1.1473 6.05661 1.14962 6.07592 1.16168C6.09523 1.17375 6.10478 1.188 6.10814 1.19623C6.10962 1.19983 6.11051 1.20347 6.11071 1.20821C6.1109 1.21274 6.11068 1.22318 6.10516 1.24076L5.87666 1.96886C4.9857 4.80792 4.98571 7.85175 5.87668 10.6908L6.10508 11.4186C6.1106 11.4362 6.11082 11.4466 6.11063 11.4512C6.11043 11.4559 6.10955 11.4595 6.10807 11.4631C6.1047 11.4714 6.09515 11.4856 6.07584 11.4977C6.05653 11.5097 6.03953 11.5121 6.03066 11.5115C6.02677 11.5112 6.02311 11.5104 6.01877 11.5085C6.01462 11.5067 6.00533 11.5019 5.99195 11.4893Z" stroke="white" stroke-width="1.14527" stroke-linecap="round"/>
                        </svg>
                    </div>
                    <div className="onplace-button" onClick={() => handleMovement('')} >
                        <div className="button-arrow">
                            <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.00805 11.4893L6.40274 6.37937C6.43144 6.35219 6.43144 6.30649 6.40274 6.27931L1.00798 1.1701C0.994596 1.15743 0.985308 1.15265 0.981153 1.15083C0.97681 1.14893 0.97315 1.14813 0.969262 1.14788C0.96039 1.1473 0.943393 1.14962 0.924082 1.16168C0.904772 1.17375 0.895224 1.188 0.891855 1.19623C0.890378 1.19983 0.889493 1.20347 0.889293 1.20821C0.889102 1.21274 0.889323 1.22318 0.894841 1.24076L1.12334 1.96886C2.0143 4.80792 2.01429 7.85175 1.12332 10.6908L0.894915 11.4186C0.889397 11.4362 0.889176 11.4466 0.889367 11.4512C0.889567 11.4559 0.890452 11.4595 0.891929 11.4631C0.895299 11.4714 0.904847 11.4856 0.924159 11.4977C0.943471 11.5097 0.96047 11.5121 0.969339 11.5115C0.973227 11.5112 0.976886 11.5104 0.981229 11.5085C0.985383 11.5067 0.994671 11.5019 1.00805 11.4893Z" stroke="white" stroke-width="1.14527" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="onplace-description-container">
                <div className="onplace-description">
                Paseá y descubrí nuestros rincones intervenidos por más de 10 artistas emergentes. Nos gusta el arte, posta.
                </div>
            </div>
            {
                    showPopup ? <ShopPopUp data={popupData}  page={"art"} show={showPopup} closeCallback={closePopup}/> : null
            }
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'min(100vw, 1920px)'}}>
                <Splide ref={splideRef} options={{
                    type: 'slide',
                    drag: 'free',
                    autoWidth: true,
                    autoHeight: true,
                    width: '100%',
                    gap: '0.55vw',
                    pagination: false,
                    arrows: false,
                    padding: {left: '30px', right: '30px'},
                    breakpoints: {
                        478: {
                            gap: '3px',
                        }
                    }
                }} >
       
                {
                    mockResponse.map((item, index) => {
                        return <SplideSlide><ExpoItem data={item} key={index} callback={openPopup}/></SplideSlide>
                    })
                }
                
                </Splide>
            </div>
            
            <div className="buttons-container hide-desktop">
                    <div className="onplace-button" onClick={() => handleMovement('prev')} >
                        <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.99195 11.4893L0.597258 6.37937C0.568562 6.35219 0.568562 6.30649 0.597262 6.27931L5.99202 1.1701C6.0054 1.15743 6.01469 1.15265 6.01885 1.15083C6.02319 1.14893 6.02685 1.14813 6.03074 1.14788C6.03961 1.1473 6.05661 1.14962 6.07592 1.16168C6.09523 1.17375 6.10478 1.188 6.10814 1.19623C6.10962 1.19983 6.11051 1.20347 6.11071 1.20821C6.1109 1.21274 6.11068 1.22318 6.10516 1.24076L5.87666 1.96886C4.9857 4.80792 4.98571 7.85175 5.87668 10.6908L6.10508 11.4186C6.1106 11.4362 6.11082 11.4466 6.11063 11.4512C6.11043 11.4559 6.10955 11.4595 6.10807 11.4631C6.1047 11.4714 6.09515 11.4856 6.07584 11.4977C6.05653 11.5097 6.03953 11.5121 6.03066 11.5115C6.02677 11.5112 6.02311 11.5104 6.01877 11.5085C6.01462 11.5067 6.00533 11.5019 5.99195 11.4893Z" stroke="white" stroke-width="1.14527" stroke-linecap="round"/>
                        </svg>
                    </div>
                    <div className="onplace-button" onClick={() => handleMovement('')} >
                        <div className="button-arrow">
                            <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.00805 11.4893L6.40274 6.37937C6.43144 6.35219 6.43144 6.30649 6.40274 6.27931L1.00798 1.1701C0.994596 1.15743 0.985308 1.15265 0.981153 1.15083C0.97681 1.14893 0.97315 1.14813 0.969262 1.14788C0.96039 1.1473 0.943393 1.14962 0.924082 1.16168C0.904772 1.17375 0.895224 1.188 0.891855 1.19623C0.890378 1.19983 0.889493 1.20347 0.889293 1.20821C0.889102 1.21274 0.889323 1.22318 0.894841 1.24076L1.12334 1.96886C2.0143 4.80792 2.01429 7.85175 1.12332 10.6908L0.894915 11.4186C0.889397 11.4362 0.889176 11.4466 0.889367 11.4512C0.889567 11.4559 0.890452 11.4595 0.891929 11.4631C0.895299 11.4714 0.904847 11.4856 0.924159 11.4977C0.943471 11.5097 0.96047 11.5121 0.969339 11.5115C0.973227 11.5112 0.976886 11.5104 0.981229 11.5085C0.985383 11.5067 0.994671 11.5019 1.00805 11.4893Z" stroke="white" stroke-width="1.14527" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
        </div>
       
        </>
    )
}

export default ExpoActuales;