import { useState } from "react";

export function usePopUp() {
    const [popupData, setPopupData] = useState({});
    const [showPopup, setShowPopup] = useState(false);

    const openPopup = (data) => {
        setPopupData(data);
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    }

    return { popupData, showPopup, openPopup, closePopup };
}