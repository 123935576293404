import React, { useEffect } from "react";
import {HashLink} from 'react-router-hash-link';
import { useState } from "react";

function VerButton({light, text, link, children, newtab, smaller, noLink}) {

    const [lgmode, setLgMode] = useState(light);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        setLgMode(light);
    }, [light])

    const buttonWrapper = {
        paddingTop: 'min(0.69vw, 10px)',
        paddingRight: 'min(2.77vw, 40px)',
        paddingBottom: 'min(0.69vw, 10px)',
        paddingLeft: 'min(2.77vw, 40px)',
        fontFamily: 'Archivo-Expanded',
        fontWeight: 400,
        fontSize: 'min(1.11vw, 16px)',
        lineHeight: 'min(2.6vw, 37.47px)',
        backgroundColor: lgmode ? '#FFFFFF' : '#000000',
        color: lgmode ? '#000000' : '#FFFFFF',
        borderRadius: 'min(13.88vw, 200px)',
        transition: 'background-color ease 0.3s, color ease 0.5s',
        width: 'fit-content'
    }

    const smallerWrapperStyle = {
        paddingTop: 'min(0.52vw, 7.53px)',
        paddingRight: 'min(2.09vw, 30.12px)',
        paddingBottom: 'min(0.52vw, 7.53px)',
        paddingLeft: 'min(2.09vw, 30.12px)',
        fontFamily: 'Archivo-Expanded',
        fontWeight: 400,
        fontSize: 'min(0.97vw, 14px)',
        lineHeight: 'min(1.95vw, 28.21px)',
        backgroundColor: lgmode ? '#FFFFFF' : '#000000',
        color: lgmode ? '#000000' : '#FFFFFF',
        borderRadius: 'min(10.45vw, 150.61px)',
        transition: 'background-color ease 0.3s, color ease 0.5s',
        width: 'fit-content'
    }

    const buttonWrapperMobile = {
        paddingTop: 'min(2.08vw, 8.57px)',
        paddingRight: 'min(6.37vw, 26.28px)',
        paddingBottom: 'min(2.08vw, 8.57px)',
        paddingLeft: 'min(6.37vw, 26.28px)',
        fontFamily: 'Archivo-Expanded',
        fontWeight: 400,
        fontSize: 'min(3.39vw, 14px)',
        lineHeight: 'min(5.97vw, 24.62px)',
        backgroundColor: lgmode ? '#FFFFFF' : '#000000',
        color: lgmode ? '#000000' : '#FFFFFF',
        borderRadius: 'min(31.89vw, 131.4px)',
        transition: 'background-color ease 0.3s, color ease 0.5s',
        width: 'fit-content'
    }

    const determineStyle = () => {
        if (window.innerWidth > 478) {
            if (smaller) {
                return smallerWrapperStyle;
            } else {
                return buttonWrapper;
            }
        } else {
            return buttonWrapperMobile;
        }
    }
    
    if(noLink == null || noLink == false) {

    return (
        <>
        <HashLink to={link} style={{textDecoration: 'none'}} target={newtab ? "_blank" : ""}>
            <div style={determineStyle()} onMouseEnter={() => {setLgMode(!lgmode)}} onMouseLeave={() => {setLgMode(!lgmode)}}>
                <div className="button-text">
                    {text != null ? text : children}
                </div>
            </div>
        </HashLink>

        </>
    )
} else {
    return (
        <>
    <div style={determineStyle()} onMouseEnter={() => {setLgMode(!lgmode)}} onMouseLeave={() => {setLgMode(!lgmode)}}>
        <div className="button-text">
            {text != null ? text : children}
        </div>
    </div>
        </>
    )

}
}

export default VerButton;