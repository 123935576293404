import React from "react";
import './components-css/service-title.css';

function ServiceTitle ({data, dark}) {

    return (
        <>
        <div className={`service-title-container ${dark ? 'service-title-dark-padding' : ''}`}>

            <div className="service-title" style={dark ? {color: 'black'} : {}}>
                {data.title}
            </div>
            <div className="service-text" style={dark ? {color: 'black'} : {}}>
                {data.text}
            </div>

        </div>

        </>
    )
}

export default ServiceTitle;