import React from "react";
import '../components-css/explora-tambien.css';

import {ReactComponent as Header} from '../../assets/imgs/components/entrenimiento-title.svg';
import EntCover from '../../assets/imgs/cards/chinchin.webp';
import VerButton from "../VerButton";

function EventosCard() {
    return (
        <>
            <div className="explora-cards-container">
                <div className="explora-left-card-wrapper" style={{backgroundColor: '#20B2EB', justifyContent: 'center'}}>
                    <img className="champagne-img" src={EntCover}  alt=""/>
                    
                </div>
                <div className="explora-right-card" style={{backgroundColor: '#1A8EBC'}}>
                    <div className="explora-right-container">
                        <div className="right-header-container">
                            <div className="right-header-text">Reunite</div>
                            <div className="right-header-title eventos-mobile-right-header-title" style={{textTransform: 'uppercase'}}>TU EVENTO ES MAS LINDO EN RONDA</div>
                        </div>
                        <div className="right-bottom-container">
                            <div className="bottom-right-text" style={{fontSize: 'min(0.97vw, 14px'}}>
                                EVENTOS
                            </div>
                            <VerButton text={"Ver más"} link={'/eventos#'} light={false}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventosCard;