import React from "react";
import './components-css/expos.css';
import { useState, useEffect, useRef } from "react";
import ShopPopUp from "./ShopPopUp";

function ExpoItem({data, callback}) {
    
    
   

    const [showArtPopUp, setshowArtPopUp] = useState(false);

    const closeFromPopUp = () => {
        setshowArtPopUp(false);
    }

    const togglePopup = () => {
        setshowArtPopUp(!showArtPopUp);
        callback(data);
    
    }

 
    

    return (

        <>

        
        <div className="expo-wrapper" >
        
            <div className="expo-image-container" onClick={togglePopup}>
                <img src={data.bgImage != null ? data.bgImage : "https://impressionistarts.com/static/f7932fc0c57091af91387f5397b00c13/14b42/renoir-moulin-de-la-galette.jpg"} alt="expo" className="expo-image"/>
            </div>
            <div className="expo-title">
                {data.title}
            </div>
        </div>
        </>
    )
}

export default ExpoItem;
