import React from "react";
import LandingBg from '../../assets/imgs/landing/landing-bg.webp';
import {ReactComponent as RondaLogo} from '../../assets/imgs/landing/ronda-logo.svg';
import './landing.css';
import {HashLink} from 'react-router-hash-link';

function Landing () {
    return (
        <>
        <div className="centering-container">
            <div className="message-container">

                <div className="welcome-text">
                    BIENVENIDOS A
                </div>
                <div className="ronda-logo">
                    <RondaLogo/>
                </div>
                <div className="welcome-subtext">
                    ¿Querés entrar a la RONDA más grande del mundo?
                </div>
                <HashLink to={'/home'} style={{textDecoration: 'none'}}>
                <div className="begin-experience-button">
                    Quiero Entrar
                </div>
                </HashLink>
              

            </div>
        </div>
        </>
    )
}

export default Landing;