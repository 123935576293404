import React from 'react'
import { useState, useEffect, useRef } from 'react';
import './eventos.css';

import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

export const EventForm = () => {

    
    //const siteKey = process.env.REACT_APP_SITE_KEY;
    //https://www.npmjs.com/package/react-google-recaptcha ver size invisble etc.

    const siteKey = useRef(null);
    const checkForSiteKey = () => {
        if (process.env.REACT_APP_SITE_KEY != null) {
            siteKey.current = process.env.REACT_APP_SITE_KEY;
        }
        return siteKey.current;
    }
    


    const recaptcha = useRef();

    const [formSent, setFormSent] = useState(false);
    const [formSentError, setFormSentError] = useState(false);
    
    async function submitForm(event) {
        event.preventDefault()
        const captchaValue = recaptcha.current.getValue()
        if (!captchaValue) {
          alert('Por favor verificar el reCAPTCHA!')
        } else {
          const res = await fetch('https://serverless-captcha-validation.vercel.app/verify', {
            method: 'POST',
            body: JSON.stringify({ captchaValue }),
            headers: {
              'content-type': 'application/json',
            },
          })
          const data = await res.json()
          if (data.success) {
            postForm();
          } else {
            alert('reCAPTCHA validation failed!')
          }
        }
      }


      const postForm = () => {
        
        let data = JSON.stringify(formData);

        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://serverless-captcha-validation.vercel.app/submit',
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
        };

        axios.request(config)
        .then((response) => {
        
        if (response.status === 200) {
            setFormSent(true);
        } else {
            setFormSentError(true);
        }

        })
        .catch((error) => {
        console.log(error);
        setFormSentError(true);
        });

      }


    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');


    const [date, setDate] = useState(null);
    const [plusEighteen, setPlusEighteen] = useState(20);
    const [underEighteen, setUnderEighteen] = useState(20);

    //event dropdown
    const [eventType, setEventType] = useState(['Evento corporativo', "Evento social"]);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [currentlySelectedEvent, setCurrentlySelectedEvent] = useState(eventType[0]);

    //date picker

    const [startTimes, setStartTimes] = useState(['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00','9:00', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '23:00']);

    const [fromTime, setFromTime] = useState('--:--');
    const [toTime, setToTime] = useState('--:--');
    const [openToTimeDropdown, setOpenToTimeDropdown] = useState(false);
    const [openFromTimeDropdown, setOpenFromTimeDropdown] = useState(false);

    const determineTimePickerWrapperStyle = () => {
        if (openFromTimeDropdown && openToTimeDropdown) {
            return {borderBottomLeftRadius: '0', borderTopLeftRadius: '0', borderBottomRightRadius: '0', borderTopRightRadius: '0'}
        } else if (openToTimeDropdown) {
            return {borderBottomRightRadius: '0', borderTopRightRadius: '10px'}
        } else if (openFromTimeDropdown) {
            return {borderBottomLeftRadius: '0', borderTopLeftRadius: '10px'}
        }
    }

    const handleDropdown = (option) => {
        setCurrentlySelectedEvent(eventType[option]);
        setOpenDropdown(false);
    }


    const handlePlusEighteen = (add) => {
        if(add){
            if(plusEighteen < 500) {
                setPlusEighteen(plusEighteen + 1);
            }
        }else{
            if(plusEighteen > 20){
                setPlusEighteen(plusEighteen - 1);
            }
        }
    }

    const handleUnderEighteen = (add) => {
        if(add){
            if(underEighteen < 500) {
                setUnderEighteen(underEighteen + 1);
            }
        }else{
            if(underEighteen > 20){
                setUnderEighteen(underEighteen - 1);
            }
        }
    }

    const handleDateChange = (date) => {
        setDate(date);
    }

    const handleTimePick = (time, from) => {
        if (from) {
            setFromTime(time);
            setOpenFromTimeDropdown(false);
        }
        else{
            setToTime(time);
            setOpenToTimeDropdown(false);
        }

    }



    const [eventDuration, setEventDuration] = useState(1);

    const handleEventDuration = (add) => {
        if(add){
            if(eventDuration < 24) {
                setEventDuration(eventDuration + 1);
            }
        }else{
            if(eventDuration > 1){
                setEventDuration(eventDuration - 1);
            }
        }
    }

    const [interests, setInterests] = useState(["mini golf", "bowling", "teatro de obras breves", "gastronomía", "cocteleria"]);
    const [selectedInterests, setSelectedInterests] = useState([]);

    const [comment, setComment] = useState('');

    useEffect(() => {
        console.log(comment);
    }, [comment])

    const calRef = useRef(null);

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    const handleCalendar = () => {
        if(calRef.current) {
            calRef.current.showPicker();
        }
    }

    const determineMinDate = () => {
        let today = new Date();
        let dd = today.getDate() + 1;
        let mm = today.getMonth() + 1;
        let yyyy = today.getFullYear();

        if(dd < 10){
            dd = '0' + dd;
        }
        if(mm < 10){
            mm = '0' + mm;
        }

        return yyyy + '-' + mm + '-' + dd;
    }

    const determinMaxDate = () => {
        let today = new Date();
        let dd = today.getDate() + 1;
        let mm = today.getMonth() + 1;
        let yyyy = today.getFullYear() + 1;

        if(dd < 10){
            dd = '0' + dd;
        }
        if(mm < 10){
            mm = '0' + mm;
        }

        return yyyy + '-' + mm + '-' + dd;
    }

    const formData = {
        email: email,
        name: name,
        lastName: lastName,
        phone: phone,
        date: date,
        plusEighteen: plusEighteen,
        underEighteen: underEighteen,
        eventType: currentlySelectedEvent,
        fromTime: fromTime,
        toTime: toTime,
        eventDuration: eventDuration,
        interests: selectedInterests,
        comment: comment
    }


    const FormSuccess = () => {
        return (
            <div className='form-success'>
                <div className='form-success-text'>Formulario enviado con éxito!</div>
            </div>
        )
    }

  return (
    <div className='event-form-wrapper'>
        <div className='form-success' style={!formSent ? {opacity: 0, display: 'none'} : {opacity: 1, display: 'flex'}}>
            <div className='checkmark-wrapper'>
                    
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enable-background="new 0 0 64 64"><path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50
                        l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" fill="#43a047"/></svg>
            
            </div>
            <div className='form-success-text'>¡Hemos recibido tu solicitud!</div>
            <div className='form-success-text'>Nos pondremos en contacto a la brevedad.</div>
        </div>
        <form className='event-form-container' onSubmit={submitForm} style={formSent ? {opacity: 0, maxHeight: '10px'} : {opacity: 1, maxHeight: 'none'}}>
            <div className='event-form-row'>
                <div className='event-form-input-container'>
                    <label className='event-form-label'>*Mail</label>
                    <input type="email" className='event-form-input' value={email} onChange={(e) => {setEmail(e.target.value)}} required></input>
                </div>
                <div className='event-form-input-container'>
                    <label className='event-form-label'>*Nombre</label>
                    <input type="text" className='event-form-input' value={name} onChange={(e) => {setName(e.target.value)}} required></input>
                </div>
            </div>
            <div className='event-form-row'>
                <div className='event-form-input-container'>
                    <label className='event-form-label'>*Apellido</label>
                    <input type="text" className='event-form-input' value={lastName} onChange={(e) => {setLastName(e.target.value)}} required></input>
                </div>
                <div className='event-form-input-container'>
                    <label className='event-form-label'>*Telefono</label>
                    <input type="text" className='event-form-input' value={phone} onChange={(e) => {setPhone(e.target.value)}} required></input>
                </div>
            </div>
            <div className='event-form-row-2'>
                <div className='event-form-input-container event-form-input-container-mobile-row'>
                    <label className='event-form-label label-max-width-mobile'>*Personas mayores de 18</label>
                    <div className='event-form-counter-input'>
                        <div className='sign-wrapper' onClick={() => {handlePlusEighteen(false)}} style={plusEighteen === 1 ? {color: '#8F8F8F'} : {}}>
                            <svg width="100%" height="100%" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.9976 11.0898H1.39355" stroke="currentColor" stroke-width="1.74901" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div className='counter-input'>{plusEighteen}</div>
                        <div className='sign-wrapper' onClick={() => {handlePlusEighteen(true)}} style={plusEighteen === 20 ? {color: '#8F8F8F'} : {}}>
                            <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.0322 22.3912V1.78711" stroke="currentColor" stroke-width="1.74901" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1.7302 12.0891H22.3343" stroke="currentColor" stroke-width="1.74901" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='event-form-input-container event-form-input-container-mobile-row'>
                    <label className='event-form-label label-max-width-mobile'>*Personas menores de 18</label>
                    <div className='event-form-counter-input'>
                        <div className='sign-wrapper' onClick={() => {handleUnderEighteen(false)}} style={underEighteen === 1 ? {color: '#8F8F8F'} : {}}>
                            <svg width="100%" height="100%" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.9976 11.0898H1.39355" stroke="currentColor" stroke-width="1.74901" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div className='counter-input'>{underEighteen}</div>
                        <div className='sign-wrapper' onClick={() => {handleUnderEighteen(true)}} style={underEighteen === 20 ? {color: '#8F8F8F'} : {}}>
                            <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.0322 22.3912V1.78711" stroke="currentColor" stroke-width="1.74901" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1.7302 12.0891H22.3343" stroke="currentColor" stroke-width="1.74901" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='dropdown-wrapper' style={{display: 'flex', flexDirection: 'column', position: 'relative'}}>
                    <div className='event-form-input-container'>
                        <label className='event-form-label'>*Ocasión</label>
                        <div className='event-form-dropdown-event-type' onClick={() => {setOpenDropdown(!openDropdown)}} style={openDropdown ? {borderBottomRightRadius: '0', borderBottomLeftRadius: '0', borderTopLeftRadius: '10px', borderTopRightRadius: '10px'} : {}}>
                            <div>{currentlySelectedEvent}</div>
                            <div className='arrow-svg-wrapper'>
                                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.1992 1.22564L7.33534 7.08951C7.27907 7.14579 7.18783 7.14579 7.13156 7.08951L1.26768 1.22564" stroke="black" stroke-width="0.813514" stroke-linecap="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className='dropdown-select-container' style={!openDropdown ? {display: 'none'} : {display: 'flex'}}>
                        {
                            eventType.map((option, index) => {
                                return <div className='dropdown-option' onClick={() => {handleDropdown(index)}}>{option}</div>
                            })
                        }
                    </div>
                </div>
            </div>
            <div className='event-form-row-3'>
                <div className='event-form-input-container'>
                    <label className='event-form-label'>*Fechas</label>
                    <div style={{position: 'relative', display: 'flex', flexDirection: 'column'}}>
                        <div type="text" className='event-form-date-input' onClick={() => {handleCalendar()}}>
                            {date == null  || date == '' ? 'Ver fechas' : date}
                            <div className='arrow-svg-wrapper'>
                                    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.1992 1.22564L7.33534 7.08951C7.27907 7.14579 7.18783 7.14579 7.13156 7.08951L1.26768 1.22564" stroke="black" stroke-width="0.813514" stroke-linecap="round"/>
                                    </svg>
                            </div>
                        </div>
                        <input ref={calRef} type="date" min={determineMinDate()} max={determinMaxDate()} className='calendar-input' value={date} onChange={(e) => {setDate(e.target.value)}} required/>
                        
                     
                    </div>
                </div>
                <div className='event-form-input-container'>
                    <label className='event-form-label'>*Horario</label>
                    <div className='event-form-date-input-container event-form-input' style={determineTimePickerWrapperStyle()}>
                        <div className='date-picker-wrapper' onClick={() => {setOpenFromTimeDropdown(!openFromTimeDropdown)}}>
                                {/*<input type='time' onChange={(event) => {setTime(event.target.value)}} value={time} className='time-input' required></input>*/}
                                <div className='time-text'>Desde: {fromTime}</div>
                                <div className='dropdown-select-container dropdown-max-height' style={!openFromTimeDropdown ? {display: 'none'} : {}}>
                                    
                                    {
                                        startTimes.map((time, index) => {
                                            return <div className='dropdown-option' onClick={() => {handleTimePick(time, true)}} key={index}>{time}</div>
                                        })
                                    }
                                
                            </div>
                        </div>
                        <div className='date-picker-divider'></div>
                        <div className='date-picker-wrapper' onClick={() => {setOpenToTimeDropdown(!openToTimeDropdown)}}>
                            {/*<input type='time' onChange={(event) => {setTime(event.target.value)}} value={time} className='time-input' required></input>*/}
                            <div className='time-text'>Hasta: {toTime}</div>
                                <div className='dropdown-select-container dropdown-max-height' style={!openToTimeDropdown ? {display: 'none'} : {}}>
                                    
                                    {
                                        startTimes.map((time, index) => {
                                            return <div className='dropdown-option' onClick={() => {handleTimePick(time, false)}} key={index}>{time}</div>
                                        })
                                    }
                                
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div className='event-form-input-container event-form-input-container-mobile-row'>
                    <label className='event-form-label label-max-width-mobile'>*Duracion del evento</label>
                    <div className='event-form-counter-input'>
                        <div className='sign-wrapper' onClick={() => {handleEventDuration(false)}} style={eventDuration === 1 ? {color: '#8F8F8F'} : {}}>
                            <svg width="100%" height="100%" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.9976 11.0898H1.39355" stroke="currentColor" stroke-width="1.74901" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div className='counter-input'>{eventDuration + "HS"}</div>
                        <div className='sign-wrapper' onClick={() => {handleEventDuration(true)}} style={eventDuration === 20 ? {color: '#8F8F8F'} : {}}>
                            <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.0322 22.3912V1.78711" stroke="currentColor" stroke-width="1.74901" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1.7302 12.0891H22.3343" stroke="currentColor" stroke-width="1.74901" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div className='event-form-input-container'>
                <label className='event-form-label'>*Interés principal (opcional)</label>
                <div className='checkbox-grid'>
                    {
                        interests.map((interest, index) => {
                            return (
                                
                                    <div className='checkbox-input' onClick={() => {
                                        if(selectedInterests.includes(interest)){
                                            setSelectedInterests(selectedInterests.filter((item) => item !== interest));
                                        }else{
                                            setSelectedInterests([...selectedInterests, interest]);
                                        }
                                    }} key={index} style={selectedInterests.includes(interest) ? {backgroundColor: '#20B2EB'} : {}}>
                                    {interest}
                                    </div>                             
                            )                        
                        })
                    }
                </div>
            </div>
            <div className='event-form-input-container'>
                <label className='event-form-label'>Querés contarnos más acerca de tu evento?</label>
                <textarea className='event-form-textarea' maxLength={3500} value={comment} onChange={(e) => {setComment(e.target.value)}}></textarea>
            </div>
            <div className='submit-row'>
            <ReCAPTCHA ref={recaptcha} sitekey={checkForSiteKey()}/>
                <button type='submit' className='event-form-submit-button'>Enviar</button>
                
            </div>
        </form>
        
    </div>
  )
}
