import {useState, useEffect} from "react";
import axios from "axios";

export function useRequest() {

    //TODO: Test cache timeout.
    //DONE: Implement localstorage cache w timeout
    //start -- cache implementations.
    const CACHE_TIMEOUT = 15 * 60 * 1000; // 1 hour in milliseconds

    function saveDataToLocalStorage(key, data) {
      const timestamp = new Date().getTime();
      const cacheData = {
        timestamp: timestamp,
        data: data
      };
      localStorage.setItem(key, JSON.stringify(cacheData));
    }

    function getDataFromLocalStorage(key) {
      const cachedData = localStorage.getItem(key);
      if (cachedData) {
        const { timestamp, data } = JSON.parse(cachedData);
        const currentTime = new Date().getTime();
        if (currentTime - timestamp <= CACHE_TIMEOUT) {
          return data; // Return cached data if within expiration time
        } else {
          localStorage.removeItem(key); // Remove expired data
        }
      }
      return null; // Return null if no valid cached data found
    }


    //end -- cache implementations.

    const URL = "https://api.estoesronda.com";
    const [shopsDataUse, setShopsDataUse] = useState([]);

    const getFoodShops = async () => {
      const cacheKey = 'foodShops';
      const cachedData = getDataFromLocalStorage(cacheKey);

      if (cachedData) {
          console.log("cache data found!");
          setShopsDataUse(cachedData);
      } else {
          let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url: `${URL}/api/gastronomias?populate=*`,
              headers: {}
          };

          await axios.request(config)
              .then((response) => {
                  const formattedData = composeNewArray(response.data, formatShopRequestData);
                  saveDataToLocalStorage(cacheKey, formattedData);
                  setShopsDataUse(formattedData);
              })
              .catch((error) => {
                  console.log(error);
              });
      }
  }

    const formatShopRequestData = (source) => {
        return {
            inPlace: source.attributes.ServicioDeMesa,
            takeAway: source.attributes.TakeAway,
            title: source.attributes.Nombre,
            subtitle: source.attributes.Tipo != null ? source.attributes.Tipo : "",
            vegan: source.attributes.Vegano,
            celiac: source.attributes.AptoCeliaco,
            type: source.attributes.Estilo != null ? source.attributes.Estilo : "SIN ESTILO",
            bgImage: source.attributes.Portada.data != null ? source.attributes.Portada.data.attributes.url : "#",
            description: source.attributes.Descripcion,
            menuLink: source.attributes.MenuLink != null ? source.attributes.MenuLink : "#",
            imagenPopUp: source.attributes.ImagenPopUp.data != null ? source.attributes.ImagenPopUp.data.attributes.url : source.attributes.Portada.data != null ? source.attributes.Portada.data.attributes.url : "#",
        }
    }

    const formatArtRequestData = (source) => {
      return {

          title: source.attributes.Nombre,
          bgImage: source.attributes.Portada.data != null ? source.attributes.Portada.data.attributes.url : "#",
          description: source.attributes.Descripcion,
          imagenPopUp: source.attributes.ImagenPopUp.data != null ? source.attributes.ImagenPopUp.data.attributes.url : source.attributes.Portada.data != null ? source.attributes.Portada.data.attributes.url : "#",
      }
  }

  const formatFashionRequestData = (source) => {
    return {

        title: source.attributes.Nombre,
        subtitle: source.attributes.Subtitulo,
        bgImage: source.attributes.Portada.data != null ? source.attributes.Portada.data.attributes.url : "#",
        description: source.attributes.Descripcion,
        verMasLink: source.attributes.VerMasLink != null ? source.attributes.VerMasLink : "#",
        imagenPopUp: source.attributes.ImagenPopUp.data != null ? source.attributes.ImagenPopUp.data.attributes.url : source.attributes.Portada.data != null ? source.attributes.Portada.data.attributes.url : "#",
    }
}

  const formatFaqRequestData = (source) => {
    return {
      type: source.attributes.Filtro != null ? source.attributes.Filtro : "",
      title: source.attributes.Pregunta,
      description: source.attributes.Respuesta,
    }
  }

    const composeNewArray = (array, formatFunction) => {
        let transformedData = [];
        array.data.map((shop) => {
            transformedData.push(formatFunction(shop));
        });
        return transformedData;
    }

    const getExposiciones = async () => {
      const cacheKey = 'exposiciones';
      const cachedData = getDataFromLocalStorage(cacheKey);

      if (cachedData) {
          setShopsDataUse(cachedData);
      } else {
          let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url: `${URL}/api/artes?populate=*`,
              headers: {}
          };

          await axios.request(config)
              .then((response) => {
                  const formattedData = composeNewArray(response.data, formatArtRequestData);
                  saveDataToLocalStorage(cacheKey, formattedData);
                  setShopsDataUse(formattedData);
              })
              .catch((error) => {
                  console.log(error);
              });
      }
  }

  const getFashionShops = async () => {
    const cacheKey = 'fashionShops';
    const cachedData = getDataFromLocalStorage(cacheKey);

    if (cachedData) {
        setShopsDataUse(cachedData);
    } else {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${URL}/api/modas?populate=*`,
            headers: {}
        };

        await axios.request(config)
            .then((response) => {
                const formattedData = composeNewArray(response.data, formatFashionRequestData);
                saveDataToLocalStorage(cacheKey, formattedData);
                setShopsDataUse(formattedData);
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

    //edit
    const getFaqs = async () => {
      const cacheKey = 'faqs';
      const cachedData = getDataFromLocalStorage(cacheKey);

      if (cachedData) {
          setShopsDataUse(cachedData);
      } else {
          let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url: `${URL}/api/faqs?pagination[start]=0&pagination[limit]=100`,
              headers: {}
          };

          await axios.request(config)
              .then((response) => {
                  const formattedData = composeNewArray(response.data, formatFaqRequestData);
                  saveDataToLocalStorage(cacheKey, formattedData);
                  setShopsDataUse(formattedData);
              })
              .catch((error) => {
                  console.log(error);
              });
      }
  }

    return {getFoodShops, shopsDataUse, getExposiciones, getFashionShops, getFaqs};

}