import { useState, useRef } from "react";

export function useSlider() {
    const splideRef = useRef(null);

    const handleMovement = (direction) => {
        if (direction === 'prev') {
          splideRef.current.go('-1');
          console.log('clicked prev');
        } else {
          splideRef.current.go('+1');
          console.log('clicked next');
        }
      }

      return { splideRef, handleMovement };
}